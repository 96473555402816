@import '~react-image-gallery/styles/css/image-gallery.css';

@font-face {
    font-family: 'Merriweather';
    font-weight: 400;
    src: url('./fonts/Merriweather-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Merriweather';
    font-weight: 700;
    src: url('./fonts/Merriweather-Bold.ttf') format('truetype');
}

body {
    background-color: #EEEDE8;
    margin: 0;
    font-family: 'Roboto', 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.error-page-container {
    align-items: center;
    display: flex;
    flex-direction: column;
}
